/*------------------------------------
  Globals
------------------------------------*/

// Variables
@import "custom/custom.variables";
@import "settings/variables";

// Mixins
@import "custom/custom.mixins";
@import "tools/mixins";


//
// Globals
//

@import "globals/globals";

// Background Styles
@import "globals/bg-attachments";
@import "globals/bg-color-gradients";
@import "globals/bg-colors";
@import "globals/bg-colors-extended";
@import "globals/bg-color-socials";
@import "globals/bg-grids";
@import "globals/bg-covers";
@import "globals/bg-image-hero";
@import "globals/bg-patterns";
@import "globals/bg-positions";
@import "globals/bg-repeat";
@import "globals/bg-sizes";

// Fill
@import "globals/fill-colors";

// Border styles
@import "globals/borders";
@import "globals/border-none";
@import "globals/border-styles";
@import "globals/border-radiuses";
@import "globals/border-dashed";
@import "globals/border-dotted";
@import "globals/border-widths";
@import "globals/border-colors";
@import "globals/border-color-socials";
@import "globals/border-color-gradients";

// Typography
@import "globals/typo-font-families";
@import "globals/typo-font-sizes";
@import "globals/typo-font-weights";
@import "globals/typo-text-transforms";
@import "globals/typo-text-decorations";
@import "globals/typo-letter-spacings";
@import "globals/typo-line-heights";
@import "globals/typo-font-styles";
@import "globals/typo-list-styles";
@import "globals/typo-text-styles";
@import "globals/quotes";

// Positions
@import "globals/positions";
@import "globals/position-spaces";

// Alignments
@import "globals/block-alignments";
@import "globals/verical-alignments";

// Others
@import "globals/blur";
@import "globals/box-shadow";
@import "globals/clears";
@import "globals/cursors";
@import "globals/overflows";
@import "globals/transitions";
@import "globals/transforms";
@import "globals/opacities";
@import "globals/z-index";
// @import "globals/absolute-reset"; /* O */
@import "globals/resize";
@import "globals/placeholder";
@import "globals/offset";

// Colors
@import "globals/colors";
@import "globals/color-socials";
@import "globals/color-gradients"; // Z

// Widths & Heighs
@import "globals/widths";
@import "globals/heights";

// Spaces (Margins & Paddings)
@import "globals/margins";
@import "globals/margins-extended";
@import "globals/paddings";

// Block Hidden
@import "globals/block-hidden";