/* Add here all your css styles (customizations) */

// Variables
@import "settings/variables";
@import "custom.variables";

// Mixins
@import "custom.mixins";

$menu-animation-time: 0.2s;

/* open-sans-300 - latin_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-v29-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v29-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v29-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v29-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v29-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v29-latin_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v29-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v29-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v29-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v29-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v29-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v29-latin_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v29-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v29-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v29-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v29-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v29-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v29-latin_cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* bebas-neue-regular - latin-ext_latin */
@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/bebas-neue-v9-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/bebas-neue-v9-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/bebas-neue-v9-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/bebas-neue-v9-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/bebas-neue-v9-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/bebas-neue-v9-latin-ext_latin-regular.svg#BebasNeue') format('svg'); /* Legacy iOS */
}


/* source-sans-pro-regular - latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/source-sans-pro-v21-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/source-sans-pro-v21-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/source-sans-pro-v21-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/source-sans-pro-v21-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin-ext_latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

.news-detail h2 {
    font-size: 26px;
    font-weight: 600;
}


.content p img {
	margin: 0 10px
}

body {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #3D5D75;
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 18px;
}

a {
    color: #ff7e2d;
}

.btn-link.focus, .btn-link:focus {
    text-decoration: none;
}

.page-link:focus, .page-link:hover, a:focus, a:hover {
    color: #ff7e2d;
}
.homebg {
    background: url("../../../../content/assets/img/bg/Landing.png") repeat-x top center;
}

.st_twitter_large .stLarge {
    background: url("../../../../content/assets/img/ico_x.png") no-repeat top center !important;
}

.u-header {
    border-bottom: 1px solid #aab9ca;
}

.u-btn-primary {
    color: #fff;
    background-color: #ff7e2d;
}

blockquote strong {
    font-weight: 600;
}

.hphone {
    border-left: 1px solid #99abbf;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.hphone i {
    margin-left: 25px;
    font-size: 16px;
}

.phonelink {
    background: url("../../../../content/assets/img/ico_phone.svg") no-repeat 15px 4px;
    display: inline-block;
    padding-left: 60px;
    font-size: 18px;
    background-size: 33px auto;
    font-weight: 600;
    line-height: 40px;
    margin-top: -2px;
    display: inline-block;
}



.maillink {
    background: url("../../../../content/assets/img/ico_mail.svg") no-repeat 2px 12px;
    display: inline-block;
    padding-left: 49px;
    font-size: 34px;
    background-size: 30px auto;
    font-weight: 400;
    line-height: 40px;
    color: #23384d;
}

.maillink:hover, .phonelink2:hover {
    text-decoration: underline;
    color: #23384d;
}

.phonelink2 {
    background: url("../../../../content/assets/img/ico_phone2.svg") no-repeat 0 6px;
    display: inline-block;
    padding-left: 49px;
    font-size: 34px;
    font-weight: 400;
    background-size: 33px auto;
    line-height: 40px;
    color: #23384d;
}

.home1 h2 {
    font-family: 'Bebas Neue', cursive;
    font-size: 82px;
    line-height: 92px;
    color: rgba(255,255,255,.8);
}

.home1 .btn {
    background: none;
    border: 1px solid #ffffff;
    color: #fff;
    font-weight: 600;
    font-size: 20px !important;
    padding: .475rem 1.3rem;
    text-transform: uppercase;
}

.home1 .btn:hover {
    border: 1px solid #1b394f;
    color: #1b394f;
}

.home2 h2, .clients h2, .moreinfo h2, .grow h2 {
    font-family: 'Bebas Neue', cursive;
    font-size: 52px;
    color: #1B394F;
    line-height: 62px;
}

.ar-features .u-icon-v2 {
    border-radius: 15px;
    border: 0;
    margin-left: 30px;
    float: left;
    width: 75px !important;
    height: 75px !important;
}

.ar-features {
    text-align: center;
}

.ar-features i {
    font-size: 60px;
    color: #ff7e2d;
}

.ar-features p {
    font-weight: 400;
    margin-left: 15px !important;
    margin-right: 15px !important;
    color: #455c73;
    display: block;
    margin-bottom: 0;
    margin-top: 14px;
    font-family: 'Source Sans Pro',sans-serif;
    line-height: 24px;
}

.ar-features .ar-features-item {
    border: 1px solid #dde4f6;
    border-radius: 5px;
    width: 100%;
    padding-bottom: 2px;
    clear: both;
    display: block;
    height: 100%;
    min-height: 180px;
    text-align: center;
    position: relative;
}

.ar-features .ar-features-item:hover {
    /*box-shadow: 0px 0px 8px #e0e1e7;*/
    text-decoration: none;
    color: #f48d14;
}

    
.ar-features .col-md-4 {
    padding-left: 10px;
    padding-right: 10px;
}

footer {
    background: #e3ecf7;
    color: #3d5d75;
    font-weight: 600 !important;
}

footer small {
    font-weight: 600 !important;
}

.btn.btn-primary.w100 {
    width: 100%;
}

.align-center {
    text-align: center !important;
}
footer a {
    color: #3d5d75;
}

.align-right {
    text-align: right;
}

.live {
    background: url(../../../../content/assets/img/bg/bg_live.png) repeat-x #d6e3f1;
}

.live .container {
    background: url("../../../../content/assets/img/bg/mac.png") no-repeat top right;
}

.live h2 {
    font-family: 'Bebas Neue', cursive;
    font-size: 52px;
    line-height: 62px;
    color: #1b394f;
}

.live p.mg-big {
    margin-bottom: 1.7rem;
}

.live p {
    color: #23384d;
    font-size: 22px;
    line-height: 33px;
}

.ar-live .u-icon-v2 {
    border-radius: 20px;
    border: 0;
    float: left;
    background: #fff;
    color: #ca1e20;
    width: 70px !important;
    height: 70px !important;
}

.ar-live p {
    margin-left: 20px;
    color: #1b394f;
    display: block;
    float: left;
    margin-bottom: 0;
    margin-top: 4px;
    width: 58%;
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 32px;
}

.slide-header {
    padding: 40px 0;
    margin-bottom: 35px;
    border-top: 1px solid #dde4f6;
    border-bottom: 1px solid #dde4f6;
}

.slide-header h3 {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 0;
    margin-top: 30px;
    color: #1b394f;
}

.modules h2 {
    font-family: 'Bebas Neue',cursive;
    font-size: 52px;
    line-height: 62px;
    color: #1b394f;
}

.modules ul {
    padding-left: 15px;
}

.modules ul li {
    margin-bottom: 15px;
}

.modules ul li ul {
    margin-top: 15px;
}

.modules ul li::marker {
    font-size: 11px;
    color: #1b394f;
}

.carousel-control-prev, .carousel-control-next {
    font-size: 11px;
    color: #1b394f;
    display: block;
    border: 1px solid #dde4f6;
    border-radius: 33px;
    width: 60px;
    height: 60px;
    text-align: center;
    top: 90px;
    right: 100px !important;
    left: auto !important;
    opacity: 1;
}

.carousel-control-next {
    right: 20px !important;
}

.carousel-control-prev:hover, .carousel-control-next:hover,
.carousel-control-prev:active, .carousel-control-next:active,
.carousel-control-prev:focus, .carousel-control-next:focus {
    color: #1b394f;
}

.carousel-control-prev span, .carousel-control-next span {
    padding-top: 24px;
    display: block;
}

.faq {
    background: #87a3bb;
}

.faq h2 {
    color: #fff;
    font-family: 'Bebas Neue',cursive;
    font-size: 52px;
    line-height: 62px;
}


.card-header:first-child {
    border-radius: 0;
}

.card, .card-header {
    border-color: #dde4f6;
}

.card-body {
    padding: 0 1.5rem .75rem;
    line-height: 21px;
    font-size: 16px;
    color: #fff !important;
}

#accordion .btn {
    white-space: normal;
}

.card-header {
    margin-bottom: 0;
    background: none;
    text-align: left;
    border-bottom: 0;
    padding: 0 0 6px;
    border-bottom-color: #dde4f6;
}

.card-header .btn.btn-link {
    display: block;
    text-align: left;
    left: 0;
    position: relative;
    width: 100%;
    color: #fff;
    font-size: 16px;
}

.card-header button {
    padding-left: 20px !important;
}

.card-header .btn.btn-link:before {
    content: "\f068";
    position: absolute;
    top: 9px;
    left: 0;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    font-size: 12px;
}

.card-header .btn.btn-link.collapsed:before {
    content: "\f067";
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    top: 9px;
    left: 0;
    font-weight: 300;
}

.card-header i {
    float: right;
    margin-top: 4px;
}

.card {
    background: none;
    border: 0;
    border-radius: 0;
}

.card:last-child .card-header {
    border-bottom: 0 !important;
}

/*.card:last-child .collapse.show {
    border-bottom: 0 !important;
    border-top: 1px solid #dde4f6 !important;
}*/

.card-header h5 .btn-link {
    color: #3d5d75;
    font-weight: 600;
    padding-left: 0;
}

.clients .client {
    border: 1px solid #dde4f6;
    background: #fff;
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.moreinfo {
    background: #e3ecf7;
}

.subtitle {
    font-size: 18px;
    line-height: 24px;
    color: #3D5D75;
}

.moreinfo-content {
    border: 1px solid #c6d0d9;
    border-radius: 4px;
    padding: 40px 75px 26px 75px;
    margin-bottom: 30px;
}

.moreinfo-contact, .moreinfo-contact2 {
    border: 1px solid #c6d0d9;
    border-radius: 4px;
    padding: 25px 10px;
    font-weight: bold;
}

.moreinfo-contact .col-md-4, .moreinfo-contact2 .col-md-4 {
    border-right: 1px solid #c6d0d9;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}

.moreinfo-contact .col-md-4:last-child, .moreinfo-contact2 .col-md-4:last-child {
    border-right: 0;
}

.moreinfo-contact i {
    color: #ca1e20;
    margin-right: 5px;
}

label {
    font-weight: bold;
    text-transform: uppercase;
}

.form-check label {
    font-weight: normal;
    text-transform: none;
}

.u-icon-v2 {
    font-size: 1.17143rem;
}

.ar-features .u-icon-v2 {
    border-radius: 30px;
    font-size: 1.37143rem;
}

.u-header__section--light .hamburger-inner, .u-header__section--light .hamburger-inner::after, .u-header__section--light .hamburger-inner::before {
    background: #fff;
}



.moreinfo-content .form-control {
    border: 0;
    padding: .575rem .75rem;
}

.moreinfo-content textarea.form-control {
    height: 140px;
}

#carouselModules .carousel-item ul {
    float: left;
    width: 50%;
    padding-left: 0;
    min-height: 140px;
    list-style-type: none;
}

#carouselModules .carousel-item ul ul {
    float: none;
    width: 100%;
    padding-left: 0;
    min-height: 1px;
    list-style-type: none;
}

#carouselModules .carousel-item ul li {
    font-weight: 600;
    padding-left: 16px;
    font-size: 16px;
    list-style-type: none;
    background: url("../../../../content/assets/img/arrow.png") no-repeat 4px 6px;
}

.btn.btn-primary {
    background: #ff740f;
    color: #fff;
    font-weight: 600;
    border: 0;
    font-size: 20px;
    padding: .475rem 1.3rem;
    text-transform: uppercase;
}

#navBar a:hover, header a:hover {
    color: #fff !important;
}

#navBar a {
    border-bottom: 3px solid transparent;
    font-size: 18px;
    font-weight: 600 !important;
}

#navBar a:hover {
    border-bottom: 3px solid #fff !important;
}

header a, #navBar a {
    color: #fff !important;
}

.ar-live a, .moreinfo-contact a.moreinfo-contact2 a {
    color: #1b394f !important;
}

#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #ff740f; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 5px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
}

#myBtn:hover {
    background-color: #ff740f; /* Add a dark-grey background on hover */
}




@media screen and (max-width: 960px) {
    /*.ar-features p {
        width: 54%;
    }*/

    .home1 h2 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 30px;
    }

    .home1 img {
        max-width: 100%;
    }

    .homebg {
        background-size: 183% auto;
    }

    .homebg.faq-page {
        background-size: auto auto;
    }

    .clients h2, .home2 h2, .live h2, .modules h2, .moreinfo h2, .grow h2 {
        font-size: 42px;
        line-height: 50px;
    }

    .live .container {
        background-size: 50% auto;
    }

    .slide-header {
        text-align: center;
    }

    .carousel-control-prev {
        left: 20px !important;
        right: auto !important;
    }

    .moreinfo-contact, .moreinfo-contact2 {
        text-align: left;
    }

    .moreinfo-contact .col-md-6 {
        border-right: 0;
        border-bottom: 1px solid #c2cef3;
        text-align: left;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .moreinfo-contact .col-md-6 i {
        font-size: 16px;
        width: 35px;
        display: inline-block;
    }

    .moreinfo-contact .col-md-6:last-child {
        border-bottom: 0;
    }

    .moreinfo-contact, .moreinfo-contact2 {
        padding: 0 20px;
        font-weight: 700;
    }
}


@media screen and (max-width: 768px) {
    .home1 h2 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 30px;
    }

    .home1 img {
        max-width: 100%;
    }

    .homebg {
        background-size: 326% auto;
    }

    .clients h2, .home2 h2, .live h2, .modules h2, .moreinfo h2, .grow h2 {
        font-size: 42px;
        line-height: 50px;
    }

    .live .container {
        background: none;
    }

    .live h2, .live p {
        text-align: center;
    }

    .ar-live p {
        text-align: left;
    }

    .faq h2 {
        font-size: 42px;
        line-height: 50px;
    }

    .moreinfo-content {
        padding: 30px 20px 26px;
        margin-bottom: 10px;
    }

    .moreinfo-content .btn.btn-primary {
        width: 100%;
        padding: .875rem 1.3rem;
    }

    .moreinfo-contact, .moreinfo-contact2 {
        text-align: left;
    }

    .moreinfo-contact .col-md-3 {
        border-right: 0;
        border-bottom: 1px solid #c2cef3;
        text-align: left;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .moreinfo-contact .col-md-3 i {
        font-size: 16px;
        width: 35px;
        display: inline-block;
    }

    .moreinfo-contact .col-md-3:last-child {
        border-bottom: 0;
    }

    .moreinfo-contact {
        padding: 0 20px;
        font-weight: 700;
    }

    footer img {
        margin-bottom: 20px;
    }

    .slide-header {
        text-align: center;
    }

    .carousel-control-prev {
        left: 20px !important;
        right: auto !important;
    }

    .live img {
        width: 100%;
        margin-bottom: 30px;
    }
}

.fa-pull-right, .pull-right, .fa-pull-right li, .pull-right li, .loginbar li {
    list-style-type: none;
}

.loginbar {
    margin-top: 8px;
    margin-left: 15px
}

.g-hidden-md-up {
    .loginbar {
        & > li > a {
            color: #fff;
        }

        & li a {
            padding: 1.07143rem;
            display: inline-block;
        }

        & .dropdown-menu {
            width: 100%;

            & li {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        padding: 0;
        margin: 0;
    }
}

.u-link-v5 .fa-angle-right {
    display: block;
    float: left;
    margin: 4px 0 6px 8px;
}

.img-responsive.full-width {
    width: 100%;
}

.input-validation-error,
.input-validation-error:focus {
    border-color: $danger-color !important;
}

.field-validation-error {
    color: $danger-color !important;
}

.validation-summary-errors {
    position: relative;
    padding: .75rem 1.25rem;
    margin: 0 0 .75rem 0 !important;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.validation-summary-errors ul {
    margin: 0;
    padding: 0 0 0 15px;
    list-style: none
}

.dropdown-menu {
    padding: 15px;
    border-radius: 0
}

.user-logged:hover {
    text-decoration: none
}

@include breakpoint(992up) {
    .min-height-article {
        min-height: 280px;
    }

    .masonry-grid-item > article {
        height: 100%
    }
}

@include breakpoint(phone) {
    .navbar-collapse {
        background-color: $g-color-primary;
    }

    .navbar-collapse .hs-sub-menu {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .g-color-white-xs, .nav-link.g-color-white-xs {
        color: #fff !important;
    }
}

.highlighted {
    font-weight: bold;
    background: #F0F0F0;
}
/* loader for jobs list loading - copied from stengl*/
.ajax-preloader {
    display: block;
    width: 100%;
    float: left;
    text-align: center;
    position: relative;
    height: 100px;
    margin: 15px 0
}

@keyframes lds-rolling {
    0% {
        transform: translate(-50%,-50%) rotate(0)
    }

    100% {
        transform: translate(-50%,-50%) rotate(360deg)
    }
}

.lds-rolling {
    position: relative;
    display: inline-block
}

.lds-rolling div, .lds-rolling div:after {
    position: absolute;
    width: 90px;
    height: 90px;
    border: 10px solid #213769;
    border-top-color: transparent;
    border-radius: 50%
}

.lds-rolling div {
    animation: lds-rolling 1s linear infinite;
    top: 100px;
    left: 100px
}

.lds-rolling div:after {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.lds-rolling {
    width: 100px !important;
    height: 100px !important;
    -ms-transform: translate(-50px,-50px) scale(.5) translate(50px,50px);
    transform: translate(-50px,-50px) scale(.5) translate(50px,50px)
}

.hidden {
    display: none;
}

.dropdown-menu li {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.alert > ul {
    margin-bottom: 0
}

.alert .fa {
    margin-right: 3px
}

ul.ui-autocomplete {
    position: absolute;
    z-index: 510;
    background: white;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.09), -2px 2px 3px rgba(0, 0, 0, 0.09);
    border-radius: 0 0 .25rem .25rem !important;
    text-align: left;
    list-style: none;
    padding-left: 0;

    li {
        margin: 0;
        cursor: pointer;

        .text {
            color: $g-color-primary;
        }

        .category {
            color: lightgray;
        }

        .annotation {
            color: #555;
            word-wrap: normal;
        }

        &.ui-state-hover,
        &.ui-state-focus,
        &.ui-menu-item:hover {
            background: $g-color-gray-light-v5 !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            font-weight: normal !important;
        }
    }
}


.sky-form .radio-inline {
    margin-right: 2em;
    text-transform: none;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .carousel-control-next, .carousel-control-prev {
        top: 50px !important;
    }
}

.right-side {
    background: #f9f9f9;
    padding-top: 20px;
    padding-bottom: 20px;
}

.right-side.nobg {
    background: none;
}

.right-side a {
    color: #1b394f;
}

.right-side h3 {
    font-family: 'Bebas Neue',cursive;
    font-size: 26px;
    color: #1b394f;
    margin-bottom: 20px;
}

.right-side table {
    width: 100%;
    margin-bottom: 40px;
    font-size: 15px;
}

.right-side table th, .right-side table td {
    padding: 7px 8px;
    background: #fff;
    white-space: nowrap;
}

.right-side table th:last-child, .right-side table td:last-child {
    text-align: right;
}

.right-side p {
    color: #1b394f;
    font-family: 'Source Sans Pro',sans-serif;
    line-height: 24px;
    font-size: 16px;
}

.reg-course, h2.reg-course {
    font-family: 'Bebas Neue',cursive;
    font-size: 36px;
    color: #1b394f;
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: 500;
}

h1.reg-course {
    font-family: 'Bebas Neue',cursive;
    font-size: 72px;
    color: #1b394f;
    margin-bottom: 20px;
    margin-top: 0;
}

.right-side ul li {
    margin-bottom: 10px;
}

.right-side .list-icons li, .list li {
    padding: 5px 0;
    font-size: 16px;
}

.pr-10 {
    padding-right: 10px;
}

.pl-20 {
    padding-left: 26px;
}

.right-side .list-icons, .right-side .list {
    list-style: none;
    padding: 0;
    line-height: 30px;
}

.btn.u-btn-primary {
    font-weight: 600;
    font-size: 20px;
    padding: .475rem 1.3rem;
    text-transform: uppercase;
}

.modal-header .close {
    padding: 0;
    margin: 0;
}

.modal-header h4 {
    float: left;
    margin-bottom: 0;
}

.modal-header {
    display: block !important;
    margin-bottom: 0;
}

.modal-body ul li ul {
    padding-top: 7px;
}

.modal-body ul li {
    margin-bottom: 4px;
}

.bg {
    background: #f9f9f9;
}

.partners .radela {
    margin: 29px 0;
    display: block;
}

.partners .slovanet {
    margin: 8px 0;
    display: block;
}

.partners .brain {
    margin: 32px 0;
    display: block;
}


#cconsent-bar .cc-text{
    padding-right: 15px;
}


.u-nav-v1-1.u-nav-primary .nav-link.active {
    color: #fff;
    background-color: #bd3c3d;
}

.g-recaptcha{
    overflow: hidden;
}

#faq {
    display: flex;

    a.nav-link {
        color: white;
    }

    .u-nav-v1-1.u-nav-primary .nav-link.active {
        background-color: #ff740f;
        border-radius: 3px;
    }
}


@include breakpoint(phone) {
    #faq {
        align-items: stretch;

        .nav-item {
            flex: 1;
            text-align: center;

            a.nav-link{
                display:inline-block;
                height: 100%;
            }
        }
    }
}



.faq-page .faq {
    background: none #fff !important;
}

.faq-page .faq h2 {
    color: #1b394f;
}

.faq-page #faq a.nav-link {
    color: #c82828;
    font-size: 1.2em;
}


.s-slide {
    float: left;
}
.faq-page #faq a.nav-link.active {
    color: #fff;
}

.faq-page #faq.g-mb-20 {
    margin-bottom: 2.72857rem !important;
}

.ico-mail {
    background: url("../../../../content/assets/img/icons/mail.png") no-repeat 0 8px;
    padding-left: 42px;
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    padding-top: 8px; 
    padding-bottom: 8px;
}

.ico-phone {
    background: url("../../../../content/assets/img/icons/phone.png") no-repeat 0 0;
    padding-left: 45px;
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
}

.ico-gps {
    background: url("../../../../content/assets/img/icons/gps.png") no-repeat 0 0;
    padding-left: 35px;
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
}

.moreinfo-contact a {
    color: #1b394f !important;
}

@media (min-width: 992px) and (max-width: 1200px) {

    .moreinfo-contact2 {
        padding: 25px 25px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .moreinfo-contact2 {
        padding: 25px 25px;
    }

    .ico-gps, .ico-mail, .ico-phone {
        font-size: 15px;
    }
}

@media (max-width: 768px) {

    .moreinfo-contact2 {
        padding: 25px 25px;
    }

    .ico-gps, .ico-mail, .ico-phone {
        font-size: 20px;
    }

    .moreinfo-contact2 .col-md-4 {
        border-right: 0;
    }

    footer .align-right {
        text-align: center;
    }

    footer .g-mr-10 {
        margin-right: 0 !important;
    }

    footer.g-py-30 {
        padding-bottom: 80px !important;
    }

    #myBtn {
        right: 0;
        
    }
}

@media (max-width: 310px) {

    .moreinfo-contact2 {
        padding: 25px 15px;
    }

    .ico-gps, .ico-mail, .ico-phone {
        font-size: 17px;
    }

   
}



.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.js-pagination {
    position: absolute;
    bottom: -20px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.js-pagination li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.js-pagination li span {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: #838ca7;
}

.js-pagination li span::before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: 'xxx';
    text-align: center;

    color: black;
}

.js-pagination li.slick-active span::before {
    background: #ff740f;
}

@media (min-width: 768px) {

    .slick-slider {
        padding: 0 0 20px 0;
    }
}

.grow img {
    max-width: 100%;
}

@media (max-width: 500px) {

    .grow-mobile {
        display: inline-block !important;
    }

    .grow-desktop {
        display: none !important;
    }
}

@media (max-width: 992px) {

    /*.home1 {
        background: #c82222 !important;
        border-bottom: 30px solid #fff !important;
    }
        */
    .home2 {
        background: #fff !important;
    }

    .faq .home1 {
        background: none !important;
        border-bottom: 0 !important;
    }
}







.fade-default {
    opacity: 0;
}

.come-in {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: come-in 0.6s ease forwards;
    animation: come-in 0.6s ease forwards;
}

.come-in.from-right {
    -webkit-transform: translateY(0) translateX(50px);
    transform: translateY(0) translateX(50px);
}

.come-in.from-left {
    -webkit-transform: translateY(0) translateX(-50px);
    transform: translateY(0) translateX(-50px);
}

.come-in.from-top {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.come-in:nth-child(odd) {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    /* So they look staggered */
}

@-webkit-keyframes come-in {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes come-in {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.form-check-input {
    margin-top: .1rem;
}

.u-header.fixed-top {
    background: #fff;
    /*box-shadow: 0px 0px 8px #e0e1e7;*/
}

.u-header.fixed-top .navbar-brand img {
    transition: all $menu-animation-time ease-in-out;
}


.u-header:not(.fixed-top) .navbar-brand img {
    max-height: 84px;
    transition: all $menu-animation-time ease-in-out;
}

#navbar_top {
    transition: all $menu-animation-time ease-in-out;
}

#bdNavbar {
    transition: all $menu-animation-time ease-in-out;
}


.u-header.fixed-top {
    background: url("../../../../content/assets/img/bg/Landing.png") repeat-x top center;
}

.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    background: none #fff;
    z-index: 1030;
}

.subfooter .g-pb-30.g-pb-20--md {
    padding-top: 1.42857rem !important;
}

@media screen and (min-width: 801px) {

    #cconsent-bar .cc-text {
        /*font-size: 16px;*/
        padding-right: 30px !important;
    }

    #cconsent-bar a.ccb__edit {
        margin-right: 30px !important;
        /*color: #e8a4a6!important;*/
    }
}

@media screen and (min-width: 1441px) {
    #cconsent-bar {
        padding: 15px !important;
        line-height: 24px !important;
    }

    #cconsent-bar .cc-text {
        /*font-size: 16px;*/
        padding-right: 30px !important;
    }

    #cconsent-bar a.ccb__edit {
        margin-right: 30px !important;
        /*color: #e8a4a6!important;*/
    }
}

@media (min-width: 411px) and (max-width: 510px) {

    #cconsent-bar button {
        padding: 6px 6px !important;
        font-size: 12px !important
    }

    #cconsent-bar {
        text-align: left !important;
    }
}

@media (max-width: 410px) {

    #cconsent-bar .ccb__right {
        white-space: normal !important;
    }

    #cconsent-bar {
        text-align: left !important;
    }

    #cconsent-bar button {
        margin-right: 0;
        margin-bottom: 5px;
        width: 100%;
    }
}

@media (min-width: 511px) and (max-width: 800px) {

    #cconsent-bar {
        text-align: left !important;
    }
}

.faq-page #faq a.nav-link {
    color: #ff7e2d;
}

.faq-page .card-body {
    color: #3d5d75 !important;
}

.faq-page .card-header .btn.btn-link {
    color: #3d5d75 !important;
}

.faq-page .card-header .btn.btn-link {
    cursor: pointer !important;
}

.ar-features img {
    height: 60px !important;
}

.form-check label {
    font-weight: 400;
    text-transform: none;
    font-size: 15px;
    margin-left: 3px;
}

.masonry-grid-item p {
    font-size: 18px;
    line-height: 1.5em;
    color: #2f3d3f;
    margin-bottom: 12px;
}

.masonry-grid-item a {
    font-family: 'Bebas Neue',cursive;
    font-size: 36px;
    line-height: 41px;
    color: #1b384f;
    display: block;
    margin-bottom: 22px;
    text-transform: uppercase;
}

.masonry-grid-item a:hover {
    color: #1b384f !important;
}

.masonry-grid-item h3 a {
    margin-bottom: 0;
}

.masonry-grid-item {
    background: inherit !important;
}

.masonry-grid-item .d-block {
    font-size: 17px;
    line-height: 17px;
    color: #697f91;
}


.color-white {
    color: #fff !important;
}

.news-detail, .news-detail p {
    font-size: 18px;
    line-height: 1.4em;
}

.list-right i {
    font-size: 12px;
}

.faq-page footer {
    padding-top: 20px;
}

.blog-top h3 a {
    font-family: 'Bebas Neue',cursive;
    font-size: 36px;
    line-height: 41px;
    color: #1b384f;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.blog-top img {
    max-width: 100%;
}

.blog-top i {
    font-family: 'Bebas Neue',cursive;
    font-size: 22px;
    font-style: normal;
    line-height: 22px;
    color: #fff;
    margin-bottom: 11px;
    display: block;
    text-transform: uppercase;
}

.blog-top p {
    font-size: 17px;
    line-height: 1.5em;
    margin-bottom: 13px;
    color: #2f3d3f;
}

.blog-top span {
    font-size: 17px;
    line-height: 17px;
    color: #697f91;
}

#faq .u-nav-v1-1.u-nav-primary .nav-link {
    font-size: 17px;
}


@media (min-width: 1200px) {
    .live .container {
        min-height: 567px;
    }
    .home1 .btn {
        width: 500px;
   }

}

@media (max-width: 992px) {
    h1.reg-course {
        font-size: 42px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .home2.g-mb-20 {
        border-bottom: 20px solid #fff !important;
        margin-bottom: 0 !important;
        padding-top: 30px !important;
    }

    .navbar-toggler.g-top-0 {
        top: 8px !important;
    }

    .home2 .g-mb-50 {
        margin-bottom: 1.5714285714rem !important;
    }

    .live.g-mb-50 {
        margin-bottom: 0 !important;
    }

    .modules {
        background: #fff !important;
        padding-top: 30px !important;
    }

    .faq.g-py-80--md {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .navbar-collapse {
        background-color: #98aec5;
    }

    #navBar a {
        color: #23384d !important;
    }
}


.paginationBox {
    text-align: center;
}

.pagination {
    display: inline-block;
    list-style: none;
    border-radius: 0;
}

.pagination li {
    display: inline-block;
    margin: 0 4px;
}


.pagination li a {
    display: block;
    font-size: 19px;
    font-weight: 600;
    padding: 12px 17px;
    background: #efefef;
    color: #1b384f;
    border-radius: 26px;
}

.pagination li a:not([href]) {
    display: block;
    font-size: 19px;
    font-weight: 600;
    padding: 12px 17px;
    background: #1b384f;
    color: #fff;
    border-radius: 26px;
}

.pagination li.arrow a {
    display: block;
    padding: 12px 17px;
    background: #efefef;
    color: #1b384f;
    font-size: 19px;
    font-weight: 400;
    border-radius: 26px;
}

.pagination li.arrow:first-child a, .pagination li.arrow:last-child a {
    display: block;
    padding: 12px 12px;
    background: #efefef;
    color: #1b384f;
    font-size: 19px;
    font-weight: 400;
    border-radius: 26px;
}

.pagination li a:hover {
    background: #ff740f !important;
    color: #fff !important;
    text-decoration: none;
}

.news-detail ul li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .moreinfo-content .btn.btn-primary {
        width: 100%;
        padding: .875rem 1.3rem;
    }

    .masonry-grid-item a {
        font-size: 26px;
        line-height: 31px;
    }
}

@media (max-width: 579px) {
    #myBtn {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 99;
        width: 100%;
        background-color: #ff7e2d;
        cursor: pointer;
        padding: 6px 15px;
        border-radius: 0;
    }

    .btn.btn-primary, .btn.btn-secondary, .clients p {
        font-size: 16px;
    }

    #myBtn {
        background-color: #ff7e2d;
    }

    .maillink {
        background: url("../../../../content/assets/img/ico_mail.svg") no-repeat 2px 15px;
    }

    .phonelink2 {
        background: url("../../../../content/assets/img/ico_phone2.svg") no-repeat 0 12px;
    }

    .ico-gps {
        background: url("../../../../content/assets/img/icons/gps.png") no-repeat 0 0;
    }

    .slide-header h3 {
        font-size: 25px;
        line-height: 38px;
        margin-top: 20px;
    }

    footer.g-pb-30 {
        padding-bottom: 3.1428571429rem !important;
    }

    .clients h2, .home2 h2, .live h2, .modules h2, .moreinfo h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .live p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 23px;
    }

    .maillink, .phonelink2 {
        font-size: 19px;
        padding-left: 29px;
    }

    .maillink, .phonelink2, ico-mail {
        background-size: 19px auto !important;
    }

    #carouselModules .carousel-item ul {
        float: none;
        width: 100%;
        min-height: 1px;
    }

    .ar-features .ar-features-item {
        min-height: 1px;
    }

    .ar-features .g-py-35 {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .modules .g-mb-50 {
        margin-bottom: 1.57143rem !important;
    }

    .slide-header {
        padding: 20px 0;
        margin-bottom: 25px;
        border-top: 1px solid #dde4f6;
        border-bottom: 1px solid #dde4f6;
    }

    .grow h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .moreinfo-contact .col-md-4, .moreinfo-contact2 .col-md-4 {
        border-right: 0;
    }

    .ico-gps, .ico-mail, .ico-phone {
        background-size: 20px auto;
        padding-left: 30px;
        background-position: 0 10px;
    }

    .ico-gps {
        background-position: 0 8px;
        background-size: 12px auto;
    }

    .moreinfo-contact .col-md-4 {
        padding-top: 0;
        padding-bottom: 10px;
    }

    .ico-mail {
        background-position: 0 13px;
    }

    #myBtn, .ico-gps, .ico-mail, .ico-phone {
        font-size: 18px;
    }

    .ico-gps {
        padding-left: 20px;
    }

    .clients .g-mb-30, .moreinfo h2 {
        margin-bottom: .7rem !important;
    }

    .grow.g-pt-60 {
        padding-top: 2.5857142857rem !important;
    }
}

.ar-live .col-sm-12.col-lg-5.g-hidden-md-down img {
    max-width: 100%;
}

@media (max-width: 991px) {
    .g-mb-30.g-mb-60--lg.clients h2 {
        color: #fff !important;
    }

    .client img {
        max-width: 70% !important;
    }

    #navBar a, #navBar a:hover {
        border-bottom: 0 !important;
    }

    .partners .radela {
        margin: 44px 0;
    }

    .partners .slovanet {
        margin: 31px 0;
    }
}


@media (max-width: 767px) {

    .partners .radela {
        margin: 31px 0;
    }

    .partners .slovanet {
        margin: 25px 0;
    }
}

@media (max-width: 575px) {

    .partners .radela {
        margin: 31px 0;
    }

    .partners .slovanet {
        margin: 5px 0;
    }
}

.stButton {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.st_facebook_large .stButton {
    margin-left: -3px !important;
}


.partners .client {
    height: 119px;
}

