/*------------------------------------
  Breadcrumbs v1
------------------------------------*/

.u-breadcrumbs-v1 {
  padding-left: 0;
  margin-bottom: 0;

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
